import React, { Component } from 'react';

// Partials
import NavbarPartial from '../partials/Navbar';
import FooterPartial from '../partials/Footer';

// React-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Images
import image1 from '../../static/img/find_unlock-1-1.svg';
import image2 from '../../static/img/safe_riding.svg';
import image3 from '../../static/img/start_bird.svg';
import image4 from '../../static/img/foot_placement.svg';
import image5 from '../../static/img/where-ride.svg';
import image6 from '../../static/img/brakes.svg';
import image7 from '../../static/img/park.svg';
import image8 from '../../static/img/ending-ride.svg';

class About extends Component {
    render() {
        return (
            <div className="">
                <NavbarPartial/>
                <div className="jumbotron pattern-2">
                    <h1 className="display-3 text-white">How It Works</h1>
                </div>
                <div className="section pt-0">
                    <Container>
                        <Row className="mb-5">
                            <Col sm="12" lg="4">
                                <h1 className="text-dark mb-3">Find & Unlock</h1>
                                <img className="img-responsive mb-3" src={image1}/>
                                <h5 className="text-center font-weight-light mb-5">Find a Fly near you and tap the button to unlock.</h5>
                            </Col>
                            <Col sm="12" lg="4"/>
                            <Col sm="12" lg="4">
                                <h1 className="text-dark mb-3">Safe Riding</h1>
                                <img className="img-responsive mb-3" src={image2}/>
                                <h5 className="text-center font-weight-light mb-5">Bring your helmet to stay safe while you ride.</h5>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col sm="12" lg="4">
                                <h1 className="text-dark mb-3">Start Fly</h1>
                                <img className="img-responsive mb-3" src={image3}/>
                                <h5 className="text-center font-weight-light mb-5">Stand on Fly, push-off, then push throttle button with thumb to ride.</h5>
                            </Col>
                            <Col sm="12" lg="4"/>
                            <Col sm="12" lg="4">
                                <h1 className="text-dark mb-3">Feet Placement</h1>
                                <img className="img-responsive mb-3" src={image4}/>
                                <h5 className="text-center font-weight-light mb-5">Place both feet on the scooter while riding.</h5>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col sm="12" lg="4">
                                <h1 className="text-dark mb-3">Where to Ride</h1>
                                <img className="img-responsive mb-3" src={image5}/>
                                <h5 className="text-center font-weight-light mb-5">Ride in bike lanes, no riding on sidewalks.</h5>
                            </Col>
                            <Col sm="12" lg="4"/>
                            <Col sm="12" lg="4">
                                <h1 className="text-dark mb-3">Brakes</h1>
                                <img className="img-responsive mb-3" src={image6}/>
                                <h5 className="text-center font-weight-light mb-5">Squeeze brake with left hand to slow down.</h5>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col sm="12" lg="4">
                                <h1 className="text-dark mb-3">Park</h1>
                                <img className="img-responsive mb-3" src={image7}/>
                                <h5 className="text-center font-weight-light mb-5">Don’t block public pathways. Park by bike racks when available.</h5>
                            </Col>
                            <Col sm="12" lg="4"/>
                            <Col sm="12" lg="4">
                                <h1 className="text-dark mb-3">Ending Your Ride</h1>
                                <img className="img-responsive mb-3" src={image8}/>
                                <h5 className="text-center font-weight-light mb-5">End your ride by tapping the end button with the app.</h5>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <FooterPartial/>
            </div>
        );
    }
}

export default About;