import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// React-bootstrap
import { Navbar, Nav, Container } from 'react-bootstrap';

// Brand
import brand from '../../static/img/brand.png';

class NavbarPartial extends Component {
    render() {
        return (
            <Navbar className="shadow-sm" expand="lg">
                <Container>
                    <Link className="navbar-brand" to="/">
                        <img src={brand}/>
                    </Link>
                    <Navbar.Toggle className="border-0" aria-controls="basic-navbar-nav">
                        <i className="fas fa-bars"/>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to="/how-it-works">How It Works</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/safety">Safety</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/charger">Become a Charger</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <a className="btn btn-theme" style={{fontSize: '13px'}} target="_blank" href="https://apps.apple.com/us/app/fly-bike-a-better-ride/id1465440550?ls=1">Download App</a>
                            </li>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default NavbarPartial;