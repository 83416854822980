import React, { Component } from 'react';

// Partials
import NavbarPartial from '../partials/Navbar';
import FooterPartial from '../partials/Footer';

// React-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Images
import image4 from '../../static/img/bike-features.png';
import usaOutline from '../../static/img/usa-outline.png';

class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <NavbarPartial/>
                <div className="feature">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 column-1">
                                <img className="img-responsive" src={require('../../static/img/fly-mockup.png')} alt=""/>
                            </div>
                            <div className="col-lg-6 column-2">
                                <h1 className="title display-3">A Better Way To Ride</h1>
                                <a target="_blank" href="https://apps.apple.com/us/app/fly-bike-a-better-ride/id1465440550?ls=1">
                                    <img style={{width:'150px'}} src={require('../../static/img/apple-store-btn.png')}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section section-info section-cards pt-0">
                    <Container>
                        <h1 className="text-center display-4 section-title">How It Works</h1>
                        <Row>
                            <Col className="mb-5" sm="12" md="4" lg="4">
                                <i className="fas fa-map-marker-alt"/>
                                <h3 className="card-title">Find</h3>
                            </Col>
                            <Col className="mb-5" sm="12" md="4" lg="4">
                                <i className="fas fa-qrcode"/>
                                <h3 className="card-title">Scan</h3>
                            </Col>
                            <Col className="mb-5" sm="12" md="4" lg="4">
                                <i className="fas fa-rocket"/>
                                <h3 className="card-title">Ride</h3>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <hr/>
                </Container>
                <div className="section">
                    <Container>
                        <h1 className="text-center display-4 section-subtitle">Where We Are</h1>
                        <span className="section-description mb-100">Originated in Los Angeles, CA<br/>FlyBike is striving to revolutionize mobile transportaion around the nation</span>
                        <div>
                            <img className="img-responsive" src={usaOutline}/>
                        </div>
                    </Container>
                </div>
                <div className="section bg-light py-5">
                    <Container>
                        <div className="mb-5 pb-5">
                            <h1 className="display-4 mb-4">The Fly Scooter</h1>
                            <h3 className="font-weight-light">$1 To Unlock + $0.15/min</h3>
                        </div>
                        <Row className="justify-content-center">
                            <Col lg="8">
                                <img className="img-responsive mb-5" src={image4}/>
                            </Col>
                            <Col lg="4">
                                <h4 className="text-left mb-4">Safe, Reliable and Fun</h4>
                                <ul className="text-left mb-5">
                                    <li>- Powerful 350W Motor</li>
                                    <li>- All-day battery life</li>
                                    <li>- 28 mile range</li>
                                    <li>- 15 mph top speed (varies by city)</li>
                                    <li></li>
                                    <li></li>
                                </ul>
                                <h4 className="text-left mb-4">Comfortable ride</h4>
                                <ul className="text-left mb-5">
                                    <li>- Dual suspension, and ergonomic controls mean a more enjoyable ride</li>
                                </ul>
                                <h4 className="text-left mb-4">Stay Safe</h4>
                                <ul className="text-left">
                                    <li>- High intensity LED headlights,  taillights, and brake lights let drivers know where you are</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <FooterPartial/>
            </React.Fragment>
        );
    }
}

export default Home;