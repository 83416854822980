import React, { Component } from 'react';

// Partials
import NavbarPartial from '../partials/Navbar';
import FooterPartial from '../partials/Footer';

// React-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

class Charger extends Component {
    render() {
        return (
            <div className="">
                <NavbarPartial/>
                <div className="jumbotron pattern-4">
                    <h1 className="display-3 text-white">Become a Charger</h1>
                </div>
                <div className="section pt-0">
                    <Container>
                        <h1 className="text-center display-4 section-subtitle">Collect and juice Fly scooters</h1>
                        <div className="section-description" style={{marginBottom: '120px'}}>
                        Make money the easy way! Simply use your own personal vehicle and electrical outlets, and get paid each time you charge a scooter.
                        </div>
                        <Row className="my-5">
                            <Col className="text-center mb-5" sm="12" md="6" lg="4">
                                <h1 className="section-subtitle" style={{fontSize: '60px', color: '#5AC2F8'}}>1 <i className="icon ion-md-log-in"/></h1>
                                <ul>
                                    <li>Collect low-battery Fly scooters</li>
                                </ul>
                            </Col>
                            <Col className="text-center mb-5" sm="12" md="6" lg="4">
                                <h1 className="section-subtitle" style={{fontSize: '60px', color: '#5AC2F8'}}>2 <i className="icon ion-md-battery-charging"/></h1>
                                <ul>
                                    <li>Charge them overnight and deploy them in the morning</li>
                                </ul>
                            </Col>
                            <Col className="text-center mb-5" sm="12" md="6" lg="4">
                                <h1 className="section-subtitle" style={{fontSize: '60px', color: '#5AC2F8'}}>3 <i className="icon ion-md-cloud-done"/></h1>
                                <ul>
                                    <li>Get paid right away</li>
                                </ul>
                            </Col>
                        </Row>
                        <a className="btn btn-theme btn-lg" href="https://work.fountain.com/jobs/Fly-Bike-Inc-Fly-Bike-Inc---Flyers-caOZxmCafxMjW0vl#/">Apply now <i className="icon ion-md-arrow-forward"/></a>
                    </Container>
                </div>
                <FooterPartial/>
            </div>
        );
    }
}

export default Charger;