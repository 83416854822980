import React, { Component } from 'react';

// Partials
import NavbarPartial from '../partials/Navbar';
import FooterPartial from '../partials/Footer';

// React-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

class TermsOfService extends Component {
    render() {
        return (
            <div className="">
                <NavbarPartial/>
                <div className="jumbotron pattern-2">
                    <h1 className="display-3 text-white">User Agreement</h1>
                </div>
                <div className="section text-left pt-0">
                    <Container>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        PLEASE READ THIS ENTIRE AGREEMENT. IT IS A CONTRACT BETWEEN YOU
                        AND FLY BIKE, INC. DBA FLY, ITS PREDECESSORS, SUCCESSORS, AFFILIATES
                        AND SUBSIDIARIES ("FLY”) AND GOVERNS YOUR ACCESS TO AND USE OF
                        ANY AND ALL PRODUCTS AND SERVICES THAT FLY BIKE PROVIDES. ANY
                        AND ALL USE OF SKIP’S PRODUCTS AND SERVICES, INCLUDING ACCESS OF
                        OR USE OF SCOOTERS, OR ACCESS TO THE WEBSITE OR ACCESS TO THE
                        MOBILE APPLICATION, AND/OR ANY PRODUCT OR SERVICE RELATED TO USE
                        OF ITS SCOOTERS, IS EXPRESSLY CONDITIONED UPON YOUR ACCEPTANCE
                        OF THE TERMS OF THIS AGREEMENT.
                        </div>
                        <div className="section-description text-dark mb-5">
                        The policies below are applicable to the Fly network of websites that link to these Terms
                        of Service (including any versions optimized for viewing on a wireless or tablet device)
                        email newsletters published or distributed by Fly Bike; apps published by Fly Bike,
                        including the “Fly” app; or any other services, interactive features, and communications
                        made available by Fly Bike, however accessed and/or used, that are operated by us,
                        made available by us, or produced and maintained by us and our related companies
                        </div>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        BY USING OUR SERVICES, YOU ARE ACCEPTING THE PRACTICES DESCRIBED
                        IN THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF
                        SERVICE, PLEASE DO NOT USE THE SERVICES. WE RESERVE THE RIGHT TO
                        MODIFY OR AMEND THESE TERMS OF SERVICE FROM TIME TO TIME WITHOUT
                        NOTICE. YOUR CONTINUED USE OF OUR SERVICES FOLLOWING THE POSTING
                        OF CHANGES TO THESE TERMS WILL MEAN YOU ACCEPT THOSE CHANGES.
                        UNLESS WE PROVIDE YOU WITH SPECIFIC NOTICE, NO CHANGES TO OUR
                        TERMS OF USE WILL APPLY.
                        </div>
                        <div className="section-description text-dark mb-5">
                        This is a legal agreement between “you” or the “user” and Fly Bike Inc. that states the
                        material terms and conditions that govern your use of the Services. This agreement,
                        together with all updates, supplements, additional terms, and all of Fly Bike’s rules and
                        policies collectively constitute this "Agreement" between you and Fly.
                        </div>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        Use Of The Product:<br/>
                        You are the Sole User of the Services : Subject to Section 1.3 below regarding the
                        limited use of Bikes by minors, You certify and expressly agree that You are the
                        sole renter and You are responsible for compliance with all terms and conditions
                        contained in this Agreement. You understand that when You activate a Product,
                        the Product must be used ONLY BY YOU. You must not allow others to use a
                        Product that You have activated.<br/>
                        </div>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        <span style={{textDecoration: 'underline'}}>You are At Least 18 Years Old</span> : You represent and certify that You are at least 18
                        years old, and that You have a valid driver’s license.
                        </div>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        1. <span style={{textDecoration: 'underline'}}>Minor Use of Bikes</span> : You may allow a minor who is at least 16 years of age
                        to use a BIKE ONLY, subject to the following conditions:<br/>
                        2. You are the minor’s parent or legal guardian;<br/>
                        3. You assume full financial and/or any other responsibility for any and all
                        misuse, consequences, claims, demands, causes of action, losses,
                        liabilities, damages, injuries, fees, costs and expenses, penalties,
                        attorneys’ fees, judgments, suits and/or disbursements of any kind, or
                        nature whatsoever, whether foreseeable or unforeseeable, and whether
                        known or unknown, as a result of the minor’s use of the Bike and/or any of
                        the Services;<br/>
                        4. You expressly guarantee, represent, and warrant that You and the minor
                        are bound by this Agreement and all of the terms herein;<br/>
                        5. You expressly guarantee, represent, and warrant that You will supervise
                        the minor at all times while the minor is using the Scooter;<br/>
                        6. You expressly guarantee, represent, and warrant that You have instructed
                        the minor how to conduct the requisite safety check of the Bike prior to
                        use;<br/>
                        7. You expressly guarantee, represent, and warrant that the minor is wearing
                        a Snell, CPSC, ANSI or ASTM approved helmet that has been properly
                        sized, fitted and fastened according to the manufacturer’s instructions at
                        all times while riding the scooter
                        </div>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        OPERATION OR USE OF ANY SCOOTER BY ANY MINOR IS EXPRESSLY
                        PROHIBITED. IF YOU ALLOW A MINOR TO USE A E-SCOOTER BY ACTIVATING
                        IT THROUGH YOUR ACCOUNT, YOU SHALL BE RESPONSIBLE FOR ANY AND
                        ALL MISUSE, CONSEQUENCES, CLAIMS, DEMANDS, CAUSES OF ACTION,
                        LOSSES, LIABILITIES, DAMAGES, INJURIES, FEES, COSTS AND EXPENSES,
                        PENALTIES, ATTORNEYS’ FEES, JUDGMENTS, SUITS AND/OR
                        DISBURSEMENTS OF ANY KIND, OR NATURE, WHETHER FORESEEABLE OR
                        UNFORESEEABLE, AND WHETHER KNOWN OR UNKNOWN.
                        </div>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        Payment Fees and Fines
                        </div>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        1. You agree to pay any fines, fees, penalties, impounding charges, court
                        costs, traffic tickets, tolls, and/or any other charges incurred by Lime, that
                        result from You parking any Product improperly, or as a result of You
                        violating any law, rule, regulation, and/or ordinance while using the
                        Services.<br/>
                        2. You agree that We may, in Our sole discretion, pay all tickets, citations,
                        fines and/or penalties on Your behalf directly to the appropriate authority,
                        and You will pay us for what we paid to the appropriate authority or their
                        designated agent(s) plus a reasonable administrative fee. You agree and
                        acknowledge that we cooperate with all federal, state, municipal and local
                        officials charged with enforcing infractions to provide any information
                        necessary as they may request or may otherwise be required.<br/>
                        3. You authorize Us to bill You directly to the credit/debit card and/or other
                        payment method You used to rent the Product.<br/>
                        4. You authorize Us to contact You directly regarding any tickets, citations,
                        fines and/or penalties incurred by You or assessed against Us or to Our
                        Product while the Product was rented to You.<br/>
                        5. You agree to pay all costs and collection fees including but not limited to
                        administrative and legal costs to such agent upon demand without protest.<br/>
                        6. You agree to indemnify and hold us harmless for any tickets, citations,
                        fines, penalties and/or administrative fees incurred as a result of Your use
                        of Our Services.
                        </div>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        YOU EXPRESSLY CERTIFY AND AGREE THAT YOU WILL NOT:
                        </div>
                        <div className="section-description font-weight-bold text-dark mb-5">
                        1. Operate any Product in violation of any laws, rules, regulations, and/or
                        ordinances, including any and all rules pertaining to riding any Products on
                        sidewalks and/or parking Products.<br/>
                        2. Operate any Product while carrying any briefcase, backpack, bag, or other
                        item if it impedes Your ability to operate safely the Product.<br/>
                        3. Use any cellular telephone, text messaging Device, portable music player,
                        and/or any other Device that may distract You from safely.<br/>
                        4. Carry any other person on any of the Products.<br/>
                        5. Use locking mechanisms other than those provided by Fly Bike Inc.<br/>
                        6. Park any Product in a manner that does not strictly comply with all
                        applicable laws, rules, regulations, and/or ordinances.<br/>
                        7. You agree that You will not use any Product for racing, mountain riding,
                        stunt, or trick riding.<br/>
                        8. impersonate any person or entity.<br/>
                        9. solicit money from any other user of Fly Bike’s Services, including any
                        rider.<br/>
                        10.“stalk” or otherwise harass any person.<br/>
                        11.express or imply that any statements you make are endorsed by Fly
                        without our specific prior written consent.<br/>
                        12.Take video, audio, photographs, or images of another Fly user without his
                        or her permission (or in the case of a minor, the minor’s legal guardian).<br/>
                        13.Take any action that may undermine the efficacy or accuracy of reviews or
                        ratings systems maintained by Fly.
                        </div>
                    </Container>
                </div>
                <FooterPartial/>
            </div>
        );
    }
}

export default TermsOfService;