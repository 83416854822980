import React, { Component } from 'react';

// Partials
import NavbarPartial from '../partials/Navbar';
import FooterPartial from '../partials/Footer';

// React-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

class About extends Component {
    render() {
        return (
            <div className="">
                <NavbarPartial/>
                <div className="jumbotron pattern-4">
                    <h1 className="display-3 text-white">About</h1>
                </div>
                <div className="section pt-0">
                    <Container>
                        <div className="section-description mb-5">
                        Fly Bike wants to help make transportation, better & more environmentally friendly. Fly
                        is founded on the idea that all communities and campus deserve access to smart and
                        safe mobility. Our teams goal is to reduce personal automobiles and save the planet
                        with our scooters. The best way to ride is with Fly.
                        </div>
                        <div className="section-description">
                        Fly Bike Inc. wants to give back to the community/city for there support in mobile
                        transportation. Our team will give back 10% of any amount a scooter makes in a day to
                        the city to create more bike lanes and reduce the amount of traffic from automobiles.
                        </div>
                    </Container>
                </div>
                <FooterPartial/>
            </div>
        );
    }
}

export default About;