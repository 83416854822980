import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Views
import Home from './containers/views/Home';
import HowItWorks from './containers/views/HowItWorks';
import About from './containers/views/About';
import Safety from './containers/views/Safety';
import Charger from './containers/views/Charger';
import Contact from './containers/views/Contact';
import TermsOfService from './containers/views/TermsOfService';
import PrivacyPolicy from './containers/views/PrivacyPolicy';

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/how-it-works" component={HowItWorks}/>
                    <Route path="/about" component={About}/>
                    <Route path="/safety" component={Safety}/>
                    <Route path="/charger" component={Charger}/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/terms-of-service" component={TermsOfService}/>
                    <Route path="/privacy-policy" component={PrivacyPolicy}/>
                </Switch>
            </Router>
        );
    }
}

export default App;
