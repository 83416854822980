import React, { Component } from 'react';

// Partials
import NavbarPartial from '../partials/Navbar';
import FooterPartial from '../partials/Footer';

// React-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="">
                <NavbarPartial/>
                <div className="jumbotron pattern-2">
                    <h1 className="display-3 text-white">Privacy Policy</h1>
                </div>
                <div className="section pt-0">
                    <Container>
                        <div className="section-description mb-5">
                        In order to better serve our customers and their concerns, our company requests certain
                        personal information pertaining to his/her personal and financial status. Our customers
                        may be concerned as to why and how the information will be utilized. Therefore, we
                        agree to disclose the customer as to how we will exploit their personal information that
                        they dispense to us on their behalf. This Privacy Policy guarantees that the handling of
                        personal customer information given to the company will only be governed under certain
                        personnel for contract and business purposes.
                        </div>
                        <hr className="mb-5"/>
                        <h1 className="section-subtitle text-left">What we offer</h1>
                        <div className="section-description text-left mb-4">
                        Our network of websites, including www.flybike.co and any versions optimized for
                        viewing on a wireless or tablet device (“Websites”)
                        </div>
                        <div className="section-description text-left mb-4">
                        All email newsletters that We publish or distribute
                        </div>
                        <div className="section-description text-left mb-4">
                        All apps that We publish, including the Fly App (“the “Apps”)
                        </div>
                        <div className="section-description text-left mb-4">
                        All products and services that We make available or provide pursuant to the User
                        Agreement and Terms of Service (collectively, for the purposes of this Privacy Notice,
                        the “Services”)
                        </div>
                        <div className="section-description text-left mb-4">
                        All other interactive features and communications that We provide, operate or make
                        available pursuant to the User Agreement and Terms of Service, no matter how
                        accessed or used
                        </div>
                        <div className="section-description text-left mb-4">
                        How We Share Your Information
                        </div>
                        <div className="section-description text-left mb-4">
                        How We Protect Your Information
                        </div>
                        <div className="section-description text-left mb-4">
                        Retention of Your Information
                        </div>
                        <div className="section-description text-left mb-4">
                        International Users
                        </div>
                        <div className="section-description text-left mb-4">
                        Third Party Links and Services
                        </div>
                        <div className="section-description text-left mb-4">
                        Changes to this Privacy Policy
                        </div>
                        <div className="section-description text-left mb-4">
                        Your California Privacy Rights
                        </div>
                        <div className="section-description text-left mb-4">
                        Fly Contact Information
                        </div>
                        <div className="section-description text-left mb-5">
                        If you have any questions about this Privacy Policy or don’t see your concerns
                        addressed here, you should contact us by email at <a href="mailto:support@flybike.co">support@flybike.co</a>
                        </div>

                        <hr className="mb-5"/>

                        <h1 className="section-subtitle text-left">The Information We Collect</h1>
                        <div className="section-description text-left mb-4">
                        We collect information about you in a variety of ways when you use our Services
                        </div>
                        <div className="section-description text-left mb-4">
                        Name
                        </div>
                        <div className="section-description text-left mb-4">
                        e-mail address
                        </div>
                        <div className="section-description text-left mb-4">
                        telephone number
                        </div>
                        <div className="section-description text-left mb-4">
                        postal address
                        </div>
                        <div className="section-description text-left mb-4">
                        credit card
                        </div>
                        <div className="section-description text-left mb-4">
                        billing and contact information
                        </div>
                        <div className="section-description text-left mb-4">
                        demographic information
                        </div>
                        <div className="section-description text-left mb-4">
                        location information (collectively, “Personal Information”)
                        </div>

                        <hr className="mb-5"/>

                        <h1 className="section-subtitle text-left">Information You Provide to Us</h1>
                        <div className="section-description text-left mb-4">
                        The following lists the most common ways in which you provide information directly to
                        us:
                        </div>
                        <div className="section-description text-left mb-4">
                        Registration for an account including your license
                        </div>
                        <div className="section-description text-left mb-4">
                        Use of your account, including the upload of content/information to the Services or the
                        rental of a Vehicle
                        </div>
                        <div className="section-description text-left mb-4">
                        Payment information submitted when renting a Vehicle (“Debit Card or Credit Card”)
                        </div>
                        <div className="section-description text-left mb-4">
                        Information that you provide for your user profile
                        </div>
                        <div className="section-description text-left mb-4">
                        Submitting an application to work at Fly Bike Inc.
                        </div>
                        <div className="section-description text-left mb-4">
                        Participation in surveys, contests, or sweepstakes
                        </div>
                        <div className="section-description text-left mb-4">
                        Signing up to receive alerts via email, text or instant message from Fly Bike
                        </div>
                        <div className="section-description text-left mb-4">
                        Requests for customer service, support requests, or other assistance
                        </div>
                        <div className="section-description text-left mb-4">
                        Service-related communications, e.g. account verification; technical notification
                        </div>
                        <div className="section-description text-left mb-4">
                        Participation in communities, commenting on blog entries, and participation in other forums
                        </div>

                        <hr className="mb-5"/>

                        <h1 className="section-subtitle text-left">How Fly protects your information</h1>
                        <div className="section-description text-left mb-5">
                        If Fly Bike learns of a security systems breach, then we will notify You electronically
                        through the contact information that you have provided to Fly so that you can take
                        appropriate protective steps. Fly will also post a notice on the App and/or the Site if any
                        problems occur.
                        </div>

                        <h1 className="section-subtitle text-left">Location Information</h1>
                        <div className="section-description text-left mb-5">
                        We collect and store location information about you associated with your account that
                        you volunteer on the Services or enable through the Services or your device. We will
                        collect precise GPS location information regarding the location of the Bird Vehicles, the
                        routes taken by these Vehicles, and the rental status of these Vehicles. We also collect
                        and store your device’s source IP address which may disclose the general location of
                        your device at the time you access the Services.
                        </div>

                        <h1 className="section-subtitle text-left">How We Protect Your Information</h1>
                        <div className="section-description text-left mb-5">
                        Fly takes serious security measures to protect your information against accidental or
                        unlawful destruction or accidental loss, alteration, unauthorized disclosure or access. As
                        such, you acknowledge and accept that we cannot guarantee the security of your
                        information transmitted to, through, or on our Services or via the Internet and that any
                        such transmission is at your own risk. We will not keep you information for no longer
                        than we need it.
                        </div>

                        <h1 className="section-subtitle text-left">If a Change Occurs In Our Policy</h1>
                        <div className="section-description text-left mb-5">
                        We reserve the right to amend this Privacy Policy at any time to reflect changes in the
                        law, our data collection and use practices, the features of our Services, or advances in
                        technology. We will make the revised Privacy Policy accessible through the Services, so
                        you should review the Policy periodically. You can know if the Privacy Policy has
                        changed since the last time you reviewed it by checking the “Effective Date” included at
                        the beginning of the document. If we make a material change to the Policy, you will be
                        provided with appropriate notice in accordance with legal requirements. By continuing to
                        use the Services, you are confirming that you have read and understood the latest
                        version of this Privacy Policy.
                        </div>

                        <h1 className="section-subtitle text-left">The Steps We Take To Keep Personal Information Secure</h1>
                        <div className="section-description text-left mb-5">
                        We are concerned about ensuring the security of Your Personal Information. We
                        exercise great care in providing secure transmission of Your information from Your
                        device to Our servers. We store Personal Information that We have collected in secure
                        operating environments that are not available to the public. Our security procedures
                        mean that We may occasionally request proof of identity before We disclose Your
                        Personal Information to You. We try Our best to safeguard Your Personal Information
                        once We receive it, but please understand that no transmission of data over the Internet
                        or any other public network can be guaranteed to be 100% secure.
                        </div>

                        <h1 className="section-subtitle text-left">Your California Privacy Rights</h1>
                        <div className="section-description text-left mb-5">
                        California Civil Code Section 1798.83 permits customers of Bird who are California
                        residents to request certain information, once per year, regarding its disclosure of their
                        personal information (as defined by California law) to third parties for their direct
                        marketing purposes. To make such a request, please send an email to <a href="mailto:contact@fliybike.com">contact@fliybike.com</a>
                        </div>

                    </Container>
                </div>
                <FooterPartial/>
            </div>
        );
    }
}

export default PrivacyPolicy;