import React, { Component } from 'react';

// Partials
import NavbarPartial from '../partials/Navbar';
import FooterPartial from '../partials/Footer';

// React-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

class Support extends Component {
    render() {
        return (
            <div className="">
                <NavbarPartial/>
                <div className="jumbotron pattern-2">
                    <h1 className="display-3 text-white">Contact</h1>
                </div>
                <div className="section pt-0">
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm="12" md="12" lg="6">
                                <form>
                                    <div className="form-group">
                                        <input className="form-control bg-light border-0 py-4" type="text" placeholder="Name"/>
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control bg-light border-0 py-4" type="text" placeholder="Email"/>
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control bg-light border-0 py-4" type="text" placeholder="Subject"/>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control bg-light border-0 py-4" placeholder="Message"></textarea>
                                    </div>
                                    <button className="btn btn-theme btn-block btn-lg">Send</button>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <FooterPartial/>
            </div>
        );
    }
}

export default Support;