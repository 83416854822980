import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FooterPartial extends Component {
    render() {
        return (
            <footer className="section section-footer">
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <div className="nav-link">© 2018. All rights reserved.</div>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/terms-of-service">Terms of Service</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/privacy-policy">Privacy Policy</Link>
                    </li>
                </ul>
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <a className="nav-link footer-sm" href="https://www.instagram.com/flybike__/" target="_blank"><i className="icon ion-logo-instagram"/></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link footer-sm" href="https://www.facebook.com/FlyBikeInc/" target="_blank"><i className="icon ion-logo-facebook"/></a>
                    </li>
                </ul>
            </footer>
        );
    }
}

export default FooterPartial;