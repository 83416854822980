import React, { Component } from 'react';

// Partials
import NavbarPartial from '../partials/Navbar';
import FooterPartial from '../partials/Footer';

// React-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// Images
import image1 from '../../static/img/safety-01.svg';
import image2 from '../../static/img/safety-02.svg';
import image3 from '../../static/img/safety-03.svg';
import image4 from '../../static/img/safety-04.svg';

class Safety extends Component {
    render() {
        return (
            <div className="">
                <NavbarPartial/>
                <div className="jumbotron pattern-3">
                    <h1 className="display-3 text-white">Safety</h1>
                </div>
                <div className="section pt-0">
                    <Container>
                        <h1 className="display-4 section-subtitle mb-4">Your Safety is Our Priority</h1>
                        <div className="section-description mb-4">We are committed to making mobile transportation safe. The safety of riders and members of the community is very important to us at Fly. Keep these safety tips in mind next time you ride.</div>
                        <hr/>
                        <Row className="align-items-center text-left mb-5">
                            <Col className="mb-5" sm="12" md="6" lg="6">
                                <img className="img-60" src={image1}/>
                            </Col>
                            <Col className="mb-5" sm="12" md="6" lg="6">
                                <h2 className="section-subtitle">Wear a Helmet</h2>
                                <ul>
                                    <li>Please protect yourself. Always wear a helmet and follow the laws.</li>
                                    <li>Fly offers free helmets to all active riders. Just cover shipping.</li>
                                </ul>
                            </Col>
                            <Col className="mb-5" sm="12" md="6" lg="6">
                                <img className="img-60" src={image2}/>
                            </Col>
                            <Col className="mb-5" sm="12" md="6" lg="6">
                                <h2 className="section-subtitle">Where to Ride</h2>
                                <ul>
                                    <li>Care for pedestrians. No riding on sidewalks — it endangers members of our community who want to walk freely. Let’s be good neighbors and look out for one another.</li>
                                    <li>Ride in bike lanes or close to the right curb.</li>
                                </ul>
                            </Col>
                            <Col className="mb-5" sm="12" md="6" lg="6">
                                <img className="img-60" src={image3}/>
                            </Col>
                            <Col className="mb-5" sm="12" md="6" lg="6">
                                <h2 className="section-subtitle">How to Park</h2>
                                <ul>
                                    <li>Park Fly out of the public right of way — keeping walkways, driveways, access ramps, and fire hydrants clear.</li>
                                    <li>Park Fly close to the curb, facing the street near designated bike or scooter parking areas, trees, or street signs.</li>
                                    <li>Make sure your kickstand is securely in the down position so that the Fly Scooter stays upright.</li>
                                    <li>Avoid uneven surfaces like grass, gravel, rocks, or inclines.</li>
                                </ul>
                            </Col>

                            <Col sm="12" md="6" lg="6">
                                <img className="img-60" src={image4}/>
                            </Col>
                            <Col sm="12" md="6" lg="6">
                                <h2 className="section-subtitle">Rules</h2>
                                <ul>
                                    <li>You must be 18+ years old with a valid driver’s license to Fly.</li>
                                    <li>Only one rider per vehicle.</li>
                                    <li>Follow all traffic rules including street signs and stop signs.</li>
                                    <li>Use caution at crosswalks.</li>
                                    <li>Cars are your biggest risk so be cautious of your surroundings.</li>
                                    <li>Start off slowly while you get used to the speed and brakes.</li>
                                    <li>No one-handed rides. Put down the phone and use two hands when you ride.</li>
                                    <li>No headphones – listen to what’s around you.</li>
                                </ul>
                            </Col>
                        </Row>
                        <hr/>
                        <div className="section-description">Fly  is committed to mobility safety, and more equitable for everyone on the road. Fly scooters not only reduce the need for cars, but also raise awareness of transportation alternatives and encourage cities to invest in safety infrastructure that benefits the community.</div>
                    </Container>
                </div>
                <FooterPartial/>
            </div>
        );
    }
}

export default Safety;